import {
  IJudicialCasesItemPeople,
  IJudicialCasesItemCompany,
} from 'store/judicialCases/types';
import { INewFormData } from './ModalWindowTypes';
import {
  INITIAL_CASE_DATA_REAL,
  INITIAL_CASE_DATA_LEGAL,
} from './ModalWindowConstants';

const getPersonDataByType = (
  dataLegal: IJudicialCasesItemCompany,
  dataReal: IJudicialCasesItemPeople,
  type: number,
) => {
  const personDataByType = {};

  switch (type) {
    case 0: //'individual'
      const legaData = { ...dataLegal, ...dataReal };
      Object.keys(INITIAL_CASE_DATA_REAL).forEach(
        key =>
          (personDataByType[key] =
            legaData[key] || INITIAL_CASE_DATA_REAL[key]),
      );
      personDataByType['person_type'] = 'individual';
      personDataByType['type'] = 0;

      return personDataByType;

    case 1: //'legal_entity'
      const legalData = { ...dataReal, ...dataLegal };
      Object.keys(INITIAL_CASE_DATA_LEGAL).forEach(
        key =>
          (personDataByType[key] =
            legalData[key] || INITIAL_CASE_DATA_LEGAL[key]),
      );
      personDataByType['person_type'] = 'legal_entity';
      personDataByType['type'] = 1;

      return personDataByType;

    default:
      return { ...dataReal, ...dataLegal };
  }
};

export const getСompletedForRequestData = (data: INewFormData) => {
  const completedData = {
    id: data.id,
    uid: data.uid,
    case_id: data.case_id,
    start: data.start,
    end: data.end,
    area_id: data.area_id,
    defendant: getPersonDataByType(
      data.defendantLegal,
      data.defendantReal,
      data.isDefendantLegal ? 1 : 0,
    ),
    plaintiff: getPersonDataByType(
      data.plaintiffLegal,
      data.plaintiffReal,
      data.isPlaintiffLegal ? 1 : 0,
    ),
  };

  delete completedData.plaintiff['same_mail'];
  delete completedData.defendant['same_mail'];

  // При редактировании, если поменять тип истца или защитника поле id станет пустым.
  // Поэтому берём его из преведущего типа
  completedData.plaintiff['id'] = data.plaintiffLegal.id
    ? data.plaintiffLegal.id
    : data.plaintiffReal.id;
  completedData.defendant['id'] = data.defendantLegal.id
    ? data.defendantLegal.id
    : data.defendantReal.id;

  return completedData;
};

export const getInitData = data => {
  const date = new Date();
  const currentDate = date.toISOString().split('T')[0];

  return data
    ? {
        id: data.id || null,
        uid: data.uid || '',
        case_id: data.case_id || '',
        area_id: data.areaId,
        start: data.start || currentDate,
        end: data.end || '',
        isPlaintiffLegal: Boolean(data.plaintiff_type) || false,
        isDefendantLegal: Boolean(data.defendant_type) || false,

        plaintiffReal: {
          ...INITIAL_CASE_DATA_REAL,
          ...data.plaintiff,
          same_mail:
            data?.plaintiff?.reg_address === data?.plaintiff?.res_address,
        },
        plaintiffLegal: {
          ...INITIAL_CASE_DATA_LEGAL,
          ...data.plaintiff,
          same_mail:
            data?.plaintiff?.legal_address === data?.plaintiff?.mailing_address,
        },

        defendantReal: {
          ...INITIAL_CASE_DATA_REAL,
          ...data.defendant,
          same_mail:
            data?.defendant?.reg_address === data?.defendant?.res_address,
        },
        defendantLegal: {
          ...INITIAL_CASE_DATA_LEGAL,
          ...data.defendant,
          same_mail:
            data?.defendant?.legal_address === data?.defendant?.mailing_address,
        },
      }
    : {
        id: null,
        uid: '',
        case_id: '',
        area_id: data.areaId,
        start: currentDate,
        end: '',
        isPlaintiffLegal: false,
        isDefendantLegal: false,
        plaintiffReal: INITIAL_CASE_DATA_REAL,
        plaintiffLegal: INITIAL_CASE_DATA_LEGAL,
        defendantReal: INITIAL_CASE_DATA_REAL,
        defendantLegal: INITIAL_CASE_DATA_LEGAL,
      };
};
