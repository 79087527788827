import React from 'react';
import { Link } from 'react-router-dom';
import { AUTH_PROTECTED_ROUTES } from 'navigation/index';
import { ISidebarProps } from './SidebarTypes';
import { PUBLIC_URL } from 'constants/api_endpoints';

const Sidebar = (props: ISidebarProps) => {
  return (
    <>
      <div className='vertical-menu'>
        <div className='navbar-brand-box'>
          <Link
            to={`${AUTH_PROTECTED_ROUTES.DEFAULT.pathTransition}`}
            className='logo logo-light'
          >
            <span className='logo-lg'>
              <img
                src={PUBLIC_URL + '/icon/conference.svg'}
                alt=''
                height='30'
              />
            </span>
          </Link>
        </div>
        <div data-simplebar className='h-100'>
          {props.children}
        </div>
        <div className='sidebar-background'></div>
      </div>
    </>
  );
};
export default Sidebar;
