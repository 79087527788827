import { getDateInFormat } from 'utils/app_helper';
import { EditBtn, DeleteBtn, OpenBtn } from './components';
import { IJudicialCasesItem, sortOrder } from './JudicialCasesTableTypes';

export const judicialCasesRenderPeopleType = (data, sort = false) => {
  if (
    !data ||
    !data.person_type ||
    (data.person_type === 'individual' &&
      !data.name &&
      !data.lastname &&
      !data.surname) ||
    (data.person_type === 'legal_entity' && !data.company)
  ) {
    return 'Некорректное имя';
  }
  if (sort) {
    return data.person_type === 'legal_entity'
      ? data.company
      : `${data.lastname}`;
  }
  return data.person_type === 'legal_entity'
    ? data.company
    : `${data?.lastname + ' ' + data?.name + ' ' + data?.surname}`;
};

export const columnsTable = [
  {
    dataField: 'numberId',
    headerClasses: 'case_id',
    headerSortingClasses: (_: any, sortOrder: sortOrder) => {
      if (sortOrder === 'desc') {
        return 'courtcases-table-header-item-sorted';
      }
      return;
    },
    text: 'Номер',
    sort: true,
  },

  {
    dataField: 'uid',
    headerClasses: '',
    text: 'Название',
    sort: true,
    headerSortingClasses: (_: any, sortOrder: sortOrder) => {
      if (sortOrder === 'desc') {
        return 'courtcases-table-header-item-sorted';
      }
      return;
    },
  },

  {
    dataField: 'start',
    headerClasses: '',
    headerSortingClasses: (_: any, sortOrder: sortOrder) => {
      if (sortOrder === 'desc') {
        return 'courtcases-table-header-item-sorted';
      }
      return;
    },
    text: 'Дата',

    sort: true,
    formatter: getDateInFormat,
  },

  {
    dataField: 'add',
    headerClasses: 'courtcases-table-header-item-7',
    text: '',
    sort: false,
    formatter: (_: any, item: IJudicialCasesItem) => {
      return (
        <div className='table-btnWrapper'>
          <EditBtn currentItem={item} />
          <DeleteBtn currentItem={item} />
          <OpenBtn caseId={item.id} />
        </div>
      );
    },
  },
];
