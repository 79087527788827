import React from 'react';
import { CardBody, Button, Modal } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation-safe';
import { getRandomInt } from 'utils/app_helper';
import { PUBLIC_URL } from 'constants/api_endpoints';

export const AddJudicialSectorPopupContent = ({
  dispatch,
  popupHandler,
  isOpen,
}) => {
  const formSubmitHandler = (e, v) => {
    e.preventDefault();
    dispatch({ ...v, district_id: getRandomInt(500) });
    closePopup();
  };
  const closePopup = (e: any = null) => {
    e?.preventDefault();
    return popupHandler(0);
  };
  return (
    <Modal
      className='court-popup-wrapper'
      isOpen={isOpen}
      toggle={closePopup}
      style={{ maxWidth: '500px' }}
      centered
    >
      <div onClick={closePopup} className='modal-closebtn'>
        <i className='fas fa-times' />
      </div>
      <div className='bg-primary bg-soft bg-relative bg__border'>
        <div onClick={() => popupHandler(0)} className='modal-closebtn'>
          <i className='fas fa-times' />
        </div>
        <div className='popup__logo_header'>
          <span className='logo-lg modal-heading'>
            <img src={PUBLIC_URL + '/icon/conference.svg'} alt='' height='30' />
          </span>
        </div>
        <div className='text-primary pb-4'>
          <h5 className='text-primary  mb-1 court__popup_title'>
            Создать департамент
          </h5>
        </div>
      </div>
      <CardBody className='modal-body'>
        <AvForm
          className='form-horizontal'
          onValidSubmit={(e, v) => formSubmitHandler(e, v)}
        >
          <div className='popup__input__wrapper'>
            <label htmlFor='classification_code' className='popup__input_title'>
              Название департамента
            </label>
            <AvField
              name='classification_code'
              className='popup__input'
              required
              errorMessage='Введите название департамента'
            />
          </div>
          <div className='popup__input__wrapper'>
            <label htmlFor='address' className='popup__input_title'>
              Район
            </label>
            <AvField
              name='address'
              className='popup__input'
              required
              errorMessage='Введите район'
            />
          </div>
          <div className='popup__input__wrapper'>
            <label htmlFor='district' className='popup__input_title'>
              Адрес
            </label>
            <AvField
              name='district'
              className='popup__input'
              required
              errorMessage='Введите адрес'
            />
          </div>
          <div className='popup__input__wrapper'>
            <label htmlFor='judge' className='popup__input_title'>
              Руководитель
            </label>
            <AvField
              name='judge'
              className='popup__input'
              required
              errorMessage='Заполните поле руководитель'
            />
          </div>

          <div className='modal-buttons-container mt-5'>
            <Button
              onClick={e => closePopup(e)}
              type='button'
              color='secondary'
            >
              Отмена
            </Button>
            <Button type='submit' color='primary'>
              Сохранить
            </Button>
          </div>
        </AvForm>
      </CardBody>
    </Modal>
  );
};

export const EditJudicialSectorPopupContent = ({
  item,
  dispatch,
  popupHandler,
  isOpen,
}) => {
  const formSubmitHandler = (e, v) => {
    e.preventDefault();
    dispatch({ ...v, id: item.id, district_id: item.district_id });
    closePopup();
  };
  const closePopup = (e: any = null) => {
    e?.preventDefault();
    return popupHandler(0);
  };
  return (
    <Modal
      className='court-popup-wrapper'
      isOpen={isOpen}
      toggle={popupHandler}
      style={{ maxWidth: '500px' }}
      centered
    >
      <div onClick={popupHandler} className='modal-closebtn'>
        <i className='fas fa-times' />
      </div>
      <div className='bg-primary bg-soft bg-relative bg__border'>
        <div onClick={closePopup} className='modal-closebtn'>
          <i className='fas fa-times' />
        </div>
        <div className='popup__logo_header'>
          <span className='logo-lg modal-heading'>
            <img src={PUBLIC_URL + '/icon/conference.svg'} alt='' height='30' />
          </span>
        </div>
        <div className='text-primary pb-4'>
          <h5 className='text-primary  mb-1 court__popup_title'>
            Изменить департамент
          </h5>
        </div>
      </div>
      <CardBody className='modal-body'>
        <AvForm
          className='form-horizontal'
          onValidSubmit={(e, v) => formSubmitHandler(e, v)}
        >
          <div className='popup__input__wrapper'>
            <label htmlFor='classification_code' className='popup__input_title'>
              Название департамента
            </label>
            <AvField
              name='classification_code'
              className='popup__input'
              required
              value={item.classification_code}
              errorMessage='Введите название департамента'
            />
          </div>
          <div className='popup__input__wrapper'>
            <label htmlFor='address' className='popup__input_title'>
              Район
            </label>
            <AvField
              name='address'
              className='popup__input'
              required
              value={item.address}
              errorMessage='Введите район'
            />
          </div>
          <div className='popup__input__wrapper'>
            <label htmlFor='district' className='popup__input_title'>
              Адрес
            </label>
            <AvField
              name='district'
              className='popup__input'
              required
              value={item.district}
              errorMessage='Введите адрес'
            />
          </div>
          <div className='popup__input__wrapper '>
            <label htmlFor='judge' className='popup__input_title'>
              Руководитель
            </label>
            <AvField
              name='judge'
              className='popup__input'
              required
              value={item.judge}
              errorMessage='Введите судью'
            />
          </div>

          <div className='mt-5 modal-buttons-container'>
            <Button color='secondary' onClick={e => closePopup(e)}>
              Отмена
            </Button>
            <Button type='submit' color='primary'>
              Сохранить
            </Button>
          </div>
        </AvForm>
      </CardBody>
    </Modal>
  );
};

export const DeleteJudicialSectorPopupContent = ({
  item,
  dispatch,
  popupHandler,
  isOpen,
}) => {
  const deletePopUpHandlerClose = ({ id }) => {
    dispatch(id);
    popupHandler(0);
  };
  return (
    <Modal
      className='court-popup-wrapper'
      isOpen={isOpen}
      toggle={popupHandler}
      style={{ maxWidth: '450px' }}
      centered
    >
      <div onClick={popupHandler} className='modal-closebtn'>
        <i className='fas fa-times' />
      </div>
      <div className='popup__delete_content'>
        <h3 className='popup__delete_text'>
          Удалить департамент № {item.districtId}?
        </h3>
        <div className='popup__delete_img'>{item.districtId}</div>
        <div className='popup__delete_btns modal-center-buttons'>
          <Button color='primary' onClick={() => deletePopUpHandlerClose(item)}>
            Ок
          </Button>
          <Button color='secondary' onClick={() => popupHandler(0)}>
            Отменить
          </Button>
        </div>
      </div>
    </Modal>
  );
};
