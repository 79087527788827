import React, { useState } from 'react';
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
  Badge,
} from 'reactstrap';
import { VALUE_RESULT_JUDICIAL_HEARINGS } from '../PanelHearing/PanelHearingConstants';
import { IHearingStatusDropdown } from 'pages/JudicialHearings/components/HearingStatusDropdown/HearingStatusDropdownTypes';
import {
  setListHearings,
  setSelectedJudicialHearingId,
  updateHearingStatus,
} from 'store/judicialHearing/actions';
import { connect } from 'react-redux';
import { RootState } from 'store/reducers';
import { IItemHearings } from 'pages/JudicialHearings/components/PanelHearing/PanelHearingTypes';

function HearingStatusDropdown({
  children,
  hearingInfo,
  updateHearingStatus,
  setSelectedJudicialHearingId,
  listHearings,
  setListHearings,
}: IHearingStatusDropdown) {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal() {
    setIsOpen(prev => !prev);
  }

  function handleStatusChange(changeStatus) {
    if (hearingInfo.id) {
      setSelectedJudicialHearingId(hearingInfo.id);
      updateHearingStatus(changeStatus);
      let hearingToChangeIndex = listHearings?.findIndex(
        hearing => hearing.id === hearingInfo.id,
      );
      if (
        (hearingToChangeIndex || hearingToChangeIndex === 0) &&
        listHearings
      ) {
        let updatedHearing: IItemHearings[] = [...listHearings];
        updatedHearing[hearingToChangeIndex] = {
          ...updatedHearing[hearingToChangeIndex],
          result: changeStatus,
        };
        setListHearings(updatedHearing);
      }
    }
  }

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={toggleModal}
      className='hearing__status-dropdown'
      onChange={handleStatusChange}
    >
      <DropdownToggle
        tag='span'
        className='hearing__status-dropdown-button d-flex align-items-center gap-2'
        style={{ cursor: 'pointer' }}
      >
        {children}
      </DropdownToggle>
      <DropdownMenu>
        {Object.entries(VALUE_RESULT_JUDICIAL_HEARINGS).map(
          ([key, value], index) => (
            <div key={index}>
              <DropdownItem
                onClick={() => {
                  handleStatusChange(key);
                }}
              >
                <Badge
                  className={`rounded-pill badge-soft-${key} bg-${key} ms-1 px-2`}
                >
                  {value}
                </Badge>
              </DropdownItem>
              {index !==
                Object.keys(VALUE_RESULT_JUDICIAL_HEARINGS).length - 1 && (
                <DropdownItem divider />
              )}
            </div>
          ),
        )}
      </DropdownMenu>
    </Dropdown>
  );
}

const mapStatetoProps = (state: RootState) => {
  const { listHearings } = state.JudicialHearing;

  return { listHearings };
};

export default connect(mapStatetoProps, {
  setSelectedJudicialHearingId,
  updateHearingStatus,
  setListHearings,
})(HearingStatusDropdown);
