import React from 'react';
import { Label, Button, Modal } from 'reactstrap';
import { Formik, Field, Form, ErrorMessage } from 'formik';

import { ITEMS_FORM } from './ModalWindowConstants';
import { getInitData, getСompletedForRequestData } from './ModalWindowHelper';
import { validationSchema } from './modalWindowValidation';
import { IModalWindowProps } from './ModalWindowTypes';
import { PUBLIC_URL } from 'constants/api_endpoints';

const ModalWindow = ({
  closeHandler,
  submitHandler,
  caseData,
  areaId,
  isOpen,
}: IModalWindowProps) => {
  const isChange = Boolean(caseData);
  const initialFormData = getInitData({ ...caseData, areaId });

  const requestConstructor = async values => {
    let requestData = getСompletedForRequestData(values);

    submitHandler(requestData);
    closeHandler();
  };

  return (
    <Modal
      className='cases-modal'
      toggle={closeHandler}
      isOpen={isOpen}
      centered
    >
      <div className='modal-closebtn' onClick={closeHandler}>
        <i className='fas fa-times' />
      </div>
      <div className='bg-primary bg-soft cases-modal__header modal-header__narrow'>
        <div className='text-primary cases-modal__header__heading-container'>
          <h3 className='text-primary'>
            {isChange ? ITEMS_FORM.alterTitle : ITEMS_FORM.title}
          </h3>
        </div>
        <div className='auth-logo-header cases-modal__header__logo'>
          <span className='logo-lg'>
            <img src={PUBLIC_URL + '/icon/conference.svg'} alt='' height='30' />
          </span>
        </div>
      </div>
      <div className='pt-0'>
        <div className='form-cardBody'>
          <Formik
            enableReinitialize={true}
            initialValues={initialFormData}
            onSubmit={values => {
              requestConstructor(values);
            }}
            validationSchema={validationSchema}
            // validateOnChange={false}
            // validateOnBlur={false}
          >
            {({ errors }) => (
              <Form>
                <div className='formGroup'>
                  <div className='Row form-field'>
                    <Label className='Col' htmlFor='uid'>
                      Название
                    </Label>
                    <Field
                      className={`Col form-control ${
                        errors.uid ? 'is-invalid' : ''
                      }`}
                      id='uid'
                      name='uid'
                    />
                    <div />
                    <ErrorMessage
                      name='uid'
                      className='invalid-feedback'
                      component='div'
                    />
                  </div>
                </div>

                <div className='Row form-field'>
                  <Label className='Col bold' htmlFor='start'>
                    Дата:
                  </Label>
                  <Field
                    type='date'
                    className={`Col form-control ${
                      errors.start ? 'is-invalid' : ''
                    }`}
                    id='start'
                    name='start'
                  />
                  <div />
                  <ErrorMessage
                    name='start'
                    className='invalid-feedback'
                    component='div'
                  />
                </div>

                <div className='formGroup'>
                  <div className='formGroup mt-5 modal-buttons-container'>
                    <Button type='button' onClick={closeHandler}>
                      Отмена
                    </Button>
                    <Button type='submit' color='primary'>
                      Сохранить
                    </Button>
                  </div>
                  <div />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Modal>
  );
};

export default ModalWindow;
