export const ITEMS_FORM = {
  title: ' Создать конференцию',
  alterTitle: 'Изменить конференцию',

  topFirstBlock: {
    inputs: [{ text: 'УИД', name: 'uid', type: 'text' }],
  },

  topSecondBlock: {
    inputs: [{ text: 'Дата:', name: 'start', type: 'date', bold: true }],
  },
};

export const INITIAL_CASE_DATA_REAL = {
  id: '',
  person_type: 'individual',
  name: '',
  lastname: '',
  surname: '',
  birthday: '',
  reg_address: '',
  res_address: '',
  phone: '',
  same_mail: false,
  type: 0,
};

export const INITIAL_CASE_DATA_LEGAL = {
  id: '',
  person_type: 'legal_entity',
  inn: '',
  ogrn: '',
  company: '',
  legal_address: '',
  mailing_address: '',
  kpp: '',
  pc: '',
  bank: '',
  bic: '',
  kc: '',
  same_mail: false,
  type: 1,
};
